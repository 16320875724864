import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, FlexLayoutModule, MatIconModule]
})
export class AppComponent {
  currentDate = new Date();

  public constructor( private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer ) {
    this.matIconRegistry.addSvgIcon(
      'icon-new-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/new-email.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/chat.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/email.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/info.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-lawfirm',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/lawfirm.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/phone.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-public',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/public.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-sri',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sri.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'icon-sro',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sro.svg',
      ),
    );
  }
}
