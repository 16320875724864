import { ENVIRONMENT, EnvConfig } from "./environments.types";

const BaseURL = 'https://fsx-modern.dev.fileandserve.com/OnlineSubscriber/';

export const environment: EnvConfig = {
    ApiServer: { BaseURL },
    CONFIG_FILE: ENVIRONMENT.DEV,
    Environment: { Env: ENVIRONMENT.DEV },
    MAX_FILE_SIZE: 100000000,
    production: false,
};