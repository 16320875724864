import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  { path: 'registration',
    title: 'Registration',
    loadComponent: () => import('./app/pages/registration/registration.component'),
  },
  { path: 'law-firm',
    title: 'Law Firm/Government Agency Registration',
    loadComponent: () => import('./app/pages/lawfirm/lawfirm.component'),
  },
  { path: 'public',
    title: 'Public Registration',
    loadComponent: () => import('./app/pages/public/public.component'),
  },
  { path: 'sro',
    title: 'Self Represented Organization Registration',
    loadComponent: () => import('./app/pages/sro/sro.component'),
  },
  { path: 'sri',
    title: 'Self Represented Individual Registration',
    loadComponent: () => import('./app/pages/sri/sri.component'),
  },
  { path: 'thank-you',
    title: 'Thank you for registering',
    loadComponent: () => import('./app/pages/thankyou/thankyou.component'),
  },
  { path: 'email-validation',
    title: 'Email Validation',
    loadComponent: () => import('./app/pages/email-validation/email-validation.component'),
  },
  // { path: 'card-validation',
  //   title: 'Card Validation',
  //   loadComponent: () => import('./app/pages/card-validation/card-validation.component'),
  // },
  { path: 'thank-you-validation',
    title: 'Thank you for validating',
    loadComponent: () => import('./app/pages/thankyou-validation/thankyou-validation.component'),
  },
  { path: '', redirectTo: 'registration', pathMatch: 'full' },
]