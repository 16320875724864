<header fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="1rem" fxLayoutGap.gt-xs="unset" fxLayoutAlign="space-between center">
  <a href='/'><img style="width: 215px" src="/assets/images/FSX-logo.webp" alt="File and Serve Express Logo" /></a>
  <aside fxLayout="row"  fxLayoutAlign="start center" fxLayoutGap=".75rem">
      <span>Already have an account?</span>
      <a class="button-link button-secondary" href="https://www.fileandservexpress.com/login/">Log in</a>
  </aside>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<footer fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" fxLayoutAlign.gt-sm="center" fxLayoutGap=".75rem" fxLayoutGap.gt-sm="unset">
  <small fxFlex>&#169; {{ currentDate | date : 'yyyy' }} File & ServeXpress All rights reserved.</small>
  <nav fxLayout="row" fxLayoutAlign.gt-md="center" fxLayoutGap="2.25rem" fxLayoutGap.gt-sm="unset" fxFlex>
    <a href="https://www.fileandservexpress.com/about-us/" target="_blank">Company</a>
    <a href="https://secure.fileandservexpress.com/agreement.htm?v=20200905" target="_blank">Legal</a>
    <a href="https://www.fileandservexpress.com/privacy/" target="_blank">Privacy</a>
  </nav>
  <section class="fsx-links" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="1.5rem" fxLayoutAlign.gt-md="end" fxFlex>
    <div><mat-icon class="footer-icon" svgIcon="icon-phone" /><a href="tel:8885297587">888 529 7587</a></div>
    <div><mat-icon class="footer-icon" svgIcon="icon-email" /><a href="email:support@fileandserve.com">support&#64;fileandserve.com</a></div>
    <div><mat-icon class="footer-icon" svgIcon="icon-chat" /><a href="javascript:void window.open('https://chat.fileandservexpress.com%E2%80%8B','','resizable=yes,scrollbars=yes,menubar=no,toolbar=no,location=0, width=490, height=760').focus();">Chat</a></div>
  </section>
</footer>