export enum ENVIRONMENT {
    DEV = 'dev',
    PROD = 'production',
    UAT = 'production',
    LOCAL = 'local',
}

export interface EnvConfig {
  ApiServer: {
    BaseURL: string;
  };
  CONFIG_FILE: ENVIRONMENT;
  Environment: {
    Env: string;
  };
  MAX_FILE_SIZE: number;
  production: boolean;
}